// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDe-o_K3LDNvLKPt_5JVrK614_qN0928JY",
    authDomain: "agilevirgin-beta.firebaseapp.com",
    databaseURL: "https://agilevirgin-beta.firebaseio.com",
    projectId: "agilevirgin-beta",
    storageBucket: "agilevirgin-beta.appspot.com",
    messagingSenderId: "846793471589",
    appId: "1:846793471589:web:3c5ebf25bc840a576b388c",
    measurementId: "G-BBXFFRD7VE",
  },
  app: {
    api: "https://api.dev.agilevirgin.in/api/",
    //api: "http://localhost:3000/api/",
    rpayKey: "rzp_test_UXnPF1QcFKIGTs",
    home: "http://localhost:8100",
    url: "https://app.dev.agilevirgin.in",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
