import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { EventService } from '../event/event.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  profile = new BehaviorSubject<any>(null);
  loggedInPing = false;


  constructor(private api: ApiService, private db: AngularFireDatabase, private afAuth: AngularFireAuth, private eventService: EventService) {
    
    this.userInit();
  }

  userInit() {
    this.afAuth.user.subscribe(usrData => {
      if (usrData && usrData.uid) {
        if (this.loggedInPing) {
          this.getProfile(usrData);

        } else {
          this.api.get('users/loggedIn', {}).then(r => {
            this.loggedInPing = true;
            this.getProfile(usrData);
          });
        }
      }
    });
  }

  getTickets(){
    return this.api.get('users/tickets', {});
  }

  transferTicket(data){
    return this.api.post('users/transferTicket', data);
  }


  saveSupportRequest(data){
    return this.api.post('users/support', data);
  }

  getNotifications(): Promise<any> {
    return this.api.get('users/notifications', {}).then(res => {
      return res.data[0];
    })
  }

  getArtifacts(): Promise<any> {
    return this.api.get('users/artifacts', {}).then(res => {
      // console.log("Artifacts", res.data);
      return res.data;
    })
  }

  getCertificates(): Promise<any> {
    return this.api.get('users/certificates', {}).then(res => {
      // console.log("Certificates", res.data);
      return res.data;
    })
  }

  getCertificateUrl(id: string){
    return this.api.get('users/certificates/'+id, {}).then(res => {
      // console.log("Certificate url", res.data);
      return res.data;
    })
  }

  getSingleArtifactSet(id:string): Promise<any> {
    return this.api.get('users/artifacts/' + id, {}).then(res => {
      // console.log("Artifact Set", res.data);
      return res.data;
    })
  }


  async getProfile(usrData) {

    this.db.database.ref('private/users/' + usrData.uid).on('value', val => {

      const usr = val.exportVal();

      if (usr) {
        const tickets = usr.tickets ? Object.keys(usr.tickets).map(key => {
          return {
            ...usr.tickets[key],
            _event: this.eventService.getEvent(usr.tickets[key].ticket_type.event)
          }
        }) : null;

        let orders = usr.orders ? Object.keys(usr.orders).map(key => {
          return {
            ...usr.orders[key],
            _event: this.eventService.getEvent(usr.orders[key].ticket.event)
          }
        }) : null

        orders = _.orderBy(orders, ['date_created'], ['desc']);

        // console.log(orders);

        this.profile.next({
          ...usr,
          _proposals: usr.proposals ? _.values(usr.proposals) : null,
          proposals: usr.proposals ? _.keyBy(usr.proposals, 'id') : null,
          tickets: tickets ? _.keyBy(_.values(tickets), 'id') : null,
          _events_participating: tickets ? _.map(tickets, t => t.ticket_type.event) : null,
          _tickets: tickets,
          _orders: orders,
          _orders_completed: _.filter(orders, order => {return order.status == 'complete'})
        });
      }
    });

  }





  syncPhone() {
    return this.api.get('users/syncPhone', {});
  }

  put(profile) {
    return this.api.put('users', profile);
  }


  async updateProfileImage(fileName) {
    return this.api.post('users/updateProfileImage', { fileName: fileName });
  }

  async search(q: string) {
    return this.api.get('/users/search/' + q, {});
  }


  async downloadInvoice(orderId: string, name: string = 'invoice.pdf') {
    return this.api.download('/orders/invoice-pdf/' + orderId, name);
  }



}
