import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthPage } from 'src/app/auth/auth.page';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth, private router: Router, private modalController: ModalController) { }

  logout() {
    this.afAuth.signOut().then(r => {
      this.router.navigate(['/']);
    });
  }


  async showAuthPopup(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: AuthPage,
        cssClass: 'my-custom-class',
        componentProps: {
          'popup': true,
        }
      });
     modal.onWillDismiss().then(res => {
      if (res.data && res.data.authComplete) {
        resolve(true);
      } else {
        resolve(false);
      }
     })

     await modal.present();

    });

  }
}
