import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import * as _ from "lodash";
import { ApiService } from "../api/api.service";
import { Router, RoutesRecognized } from "@angular/router";
import { filter, pairwise } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BasicService {
  brands;
  settings;
  previousUrl = new BehaviorSubject<any>(null);

  constructor(
    private db: AngularFireDatabase,
    private apiService: ApiService,
    private router: Router,
  ) {
    this.getBrands();

    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise())
      .subscribe((e: any) => {
        this.previousUrl.next(e[0].urlAfterRedirects); // previous url
      });
  }

  getBrands(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.brands) {
        resolve(this.brands);
      } else {
        const brandsRef = this.db.database.ref("brands");
        return brandsRef.get().then((res) => {
          this.brands = _.values(res.exportVal());
          resolve(this.brands);
        });
      }
    });
  }
}
