import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import * as _ from "lodash";
import { UtilsService } from "../utils/utils.service";

@Injectable({
  providedIn: "root",
})
export class EventService {
  constructor(private db: AngularFireDatabase, private utils: UtilsService) {
    // this.getEventBySlug('funconf-2021');
  }

  getEventBySlug(brandSlug, eventSlug) {
    return new Promise((resolve, reject) => {
      const ref = this.db.database.ref("events");

      ref.orderByChild("combined_slug").equalTo(brandSlug + "-" + eventSlug)
        .limitToFirst(1).once("value").then((snapshot) => {
          // console.log('event by slug', brandSlug, eventSlug, Object.keys(snapshot.exportVal())[0]);

          if (snapshot.exists()) {
            resolve(this.getEvent(Object.keys(snapshot.exportVal())[0]));
          } else {
            // Event Not FOund
            this.utils.pageNotFound();
          }
        });
    });
  }

  async getTickers(eventId) {
    const globalTickers = (await this.db.database.ref("tickers/global").get())
      .exportVal();
    const eventTickers =
      (await this.db.database.ref("tickers/" + eventId).get()).exportVal();
    let tickers = [];
    tickers = eventTickers && eventTickers.tickers
      ? _.values(eventTickers.tickers)
      : tickers;
    tickers = globalTickers && globalTickers.tickers
      ? _.concat(tickers, _.values(globalTickers.tickers))
      : tickers;
    return tickers && tickers.length > 0 ? tickers : null;
  }

  getBrandBySlug(brandSlug) {
    return new Promise((resolve, reject) => {
      const ref = this.db.database.ref("brands");
      ref.orderByChild("slug").equalTo(brandSlug).on(
        "child_added",
        (snapshot) => {
          resolve(this.getBrand(snapshot.key));
        },
      );
      setInterval(() => {
        reject("Brand Not Found");
      }, 7000);
    });
  }

  getBrand(id) {
    return this.db.database.ref("brands/" + id).get().then(async (res) => {
      let brand;
      brand = res.exportVal();
      brand = {
        ...brand,
        _path: "/experience/" + brand["slug"],
      };

      brand.galleries = brand.galleries ? _.values(brand.galleries) : null;
      brand.past_events = brand.past_events
        ? _.values(brand.past_events)
        : null;
      brand.subpages = brand.subpages ? _.values(brand.subpages) : null;
      brand.testimonials = brand.testimonials
        ? _.values(brand.testimonials)
        : null;

      if (brand.events) {
        brand.events = _.map(_.values(brand.events), async (e) => {
          return await this.getEvent(e, false);
        });

        return Promise.all(brand.events).then((res) => {
          return {
            ...brand,
            events: res,
          };
        });
      } else {
        return brand;
      }
    });
  }

  getEvent(id, deep = true) {
    return this.db.database.ref("events/" + id).get().then(async (res) => {
      let event;
      event = res.exportVal();

      if (event && event.brand) {
        event.brand = await this.setBrand(event.brand);
        if (event.start_date) {
          event.start_date = new Date(event.start_date + "+0530");
          event.end_date = new Date(event.end_date + "+0530");

          const diff = this.calculateDiff(event.start_date);
          event.start_date_diff = diff > 0 ? diff : null;

          const end_diff = event.end_date
            ? this.calculateDiff(event.end_date)
            : null;
          event.end_date_diff = end_diff > 0 ? end_diff : null;
        }
        event["pagesInMenu"] = [];
        event["_path"] = "/experience/" + event["brand"].slug + "/" +
          event["slug"];

        if (event["subpages"]) {
          Object.keys(event["subpages"]).map((key, index) => {
            if (
              event["subpages"][key].show_in_menu &&
              event["subpages"][key].menu_name
            ) event.pagesInMenu.push(event["subpages"][key]);
          });
        }

        if (event["sub_events"] && deep) {
          let allSubEvents = _.values(event["sub_events"]);
          let final_events = new Promise((resolve, reject) => {
            resolve(Promise.all(_.map(allSubEvents, async (ev) => {
              return await this.getEvent(ev, false);
            })));
          });

          final_events.then((r: any) => {
            // console.log('r', r);
            const subevents = _.orderBy(r, ["start_date"], ["asc"]);
            // console.log("fe", subevents);
            if (subevents) {
              event["_sub_events"] = subevents;
              event["_sub_events_upcoming"] = _.filter(subevents, (e) => {
                return e && e.end_date ? e.end_date > new Date() : false;
              });
              event["_sub_events_ended"] = _.filter(subevents, (e) => {
                return e && e.end_date ? e.end_date < new Date() : false;
              });
            }
          });
        }

        if (event["attach_to_fest"] && deep) {
          this.getEvent(event["attach_to_fest"], false).then((res) => {
            event["attach_to_fest"] = res;
          });
        }

        if (event["tickets"]) {
          event["_valid_tickets"] = _.filter(
            _.values(event["tickets"]),
            (e) => {
              return this.isDateInRange(e.valid_from, e.valid_upto);
            },
          );
        }

        if (event["sponsor_categories"]) {
          event["sponsor_categories"] = _.values(event["sponsor_categories"]);
          event["sponsor_categories"] = _.orderBy(
            event["sponsor_categories"],
            "order",
          );

          event["_sponsor_primary"] = _.filter(
            _.values(event["sponsor_categories"]),
            (e) => {
              return e.type == "primary";
            },
          );
          event["_sponsor_secondary"] = _.filter(
            _.values(event["sponsor_categories"]),
            (e) => {
              return e.type == "secondary";
            },
          );
        }

        if (event.speakers) {
          event.speakers = await Promise.all(
            _.map(_.values(event.speakers), (r) => {
              return this.getPublicProfile(r.id);
            }),
          );
        }
        if (event.judges) {
          event.judges = await Promise.all(
            _.map(_.values(event.judges), (r) => {
              return this.getPublicProfile(r.id);
            }),
          );
        }

        if (event.team) {
          event.team = await Promise.all(_.map(_.values(event.team), (r) => {
            return this.getPublicProfile(r.id);
          }));
        }

        if (event.board_members) {
          event.board_members = await Promise.all(
            _.map(_.values(event.board_members), (r) => {
              return this.getPublicProfile(r.id);
            }),
          );
        }

        event["galleries"] = event["galleries"]
          ? _.values(event["galleries"])
          : null;

        event["selected_proposals"] = event["selected_proposals"]
          ? _.values(event["selected_proposals"])
          : null;

        event["tickers"] = this.getTickers(id);

        return event;
      }
    });
  }

  getPublicProfile(id) {
    // console.log("gettin for", id)
    return this.db.database.ref("users/" + id).get().then((res) => {
      // console.log('uuu', res.exportVal());
      return res.exportVal();
    });
  }

  setBrand(id) {
    return this.db.database.ref("brands/" + id).get().then((res) => {
      return res.exportVal();
    });
  }

  /* async setUserProfile(users) {
    return new Promise(async (resolve,reject) => {
      const allUsers = _.values(users);
      // console.log('allUsers', allUsers);
      let profiles = await Promise.all(_.map(allUsers, async u => {
        if (u.id) {
          return await this.getUserProfile(u.id)
        } else {
          return null;
        }
      }));

      profiles = _.compact(profiles);
      // console.log('pppp', _.keyBy(profiles, "id"));
      resolve(_.keyBy(profiles, "id"));
    })


  } */

  calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);

    return Math.floor(
      (Date.UTC(
        dateSent.getFullYear(),
        dateSent.getMonth(),
        dateSent.getDate(),
      ) -
        Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
        )) / (1000 * 60 * 60 * 24),
    );
  }

  isDateInRange(startDate, endDate) {
    const now = new Date();
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    return date2 > now && date1 < now;
  }
}
