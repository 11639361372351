import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-headbar',
  templateUrl: './headbar.component.html',
  styleUrls: ['./headbar.component.scss'],
})
export class HeadbarComponent implements OnInit {

  processing = false;

  constructor(private authService: AuthService) { }

  ngOnInit() {}

  logout(){
    this.processing = true;
    this.authService.logout();
  }

}
