import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AlertController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Axios from 'axios';



@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private afAuth: AngularFireAuth, public alertController: AlertController) { }


  getHeader() {
    return new Promise((resolve, reject) => {
      this.afAuth.idToken.pipe(take(1)).subscribe(tok => {
        if (tok) {
          resolve({ "Authorization": "Bearer " + tok })
        }
      });
    })
  }

  async get(path: string, params, restricted: boolean = true) {
    const request = {
      url: path,
      baseURL: environment.app.api,
      headers: restricted ? await this.getHeader() : null,
      params: params,
      transformResponse: [function (data) {
        return JSON.parse(data);
      }]
    }
    // console.log(request);
    return await Axios(request);

  }

  async download(path: string, filename, restricted: boolean = true) {
    return new Promise(async (resolve, reject) => {
      const res = await Axios.get(path, {
        responseType: 'blob',
        baseURL: environment.app.api,
        headers: restricted ? await this.getHeader() : null,
      });

      // const res = await Axios(request);
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click();
      resolve(true);
    })



  }


  async post(path: string, body: any, restricted: boolean = true) {
    let request: any;
    request = {
      method: 'POST',
      url: path,
      baseURL: environment.app.api,
      headers: restricted ? await this.getHeader() : null,
      data: body,
      // responseType: <ResponseType>'json',
      transformResponse: [function (data) {
        return JSON.parse(data);
      }]
    }
    // console.log(request);
    return Axios(request);

  }

  async put(path: string, body: any, restricted: boolean = true) {
    let request: any;
    request = {
      method: 'PUT',
      url: path,
      baseURL: environment.app.api,
      headers: restricted ? await this.getHeader() : null,
      data: body,
      // responseType: <ResponseType>'json',
      transformResponse: [function (data) {
        return JSON.parse(data);
      }]
    }
    // console.log(request);
    return Axios(request);

  }


  async delete(path: string, restricted: boolean = true) {
    let request: any;
    request = {
      method: 'DELETE',
      url: path,
      baseURL: environment.app.api,
      headers: restricted ? await this.getHeader() : null,
      transformResponse: [function (data) {
        return JSON.parse(data);
      }]
    }
    // console.log(request);
    return Axios(request);

  }




  async showMessage(header: string, subHeader: string, message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

}
