import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils/utils.service';


@Component({
  selector: 'app-event-menu',
  templateUrl: './event-menu.component.html',
  styleUrls: ['./event-menu.component.scss'],
})
export class EventMenuComponent implements OnInit {
  @Input() event;
  @Input() type;
  infoPath;
  eventPath;

  constructor(private utils: UtilsService) { }

  ngOnInit() {
    this.infoPath = this.event["_path"] + '/info/';
    this.eventPath = this.event["_path"];
  }

  share() {
    this.utils.share(null, this.event.name);
  }

}
