import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {

  notVerified = false;

  constructor(private afAuth: AngularFireAuth, private utils: UtilsService) { }

  ngOnInit() {
    this.afAuth.user.subscribe(u => {
      this.notVerified = u.emailVerified ? false : true;
    })
  }

  verify() {
    this.afAuth.currentUser.then(r => {

      r.sendEmailVerification().then(() => {
        this.utils.simpleAlert('Check your Email', r.email, 'Kindly check your email and open the verification link.')
        // console.log('email sent');
    });

    })
  }

}
