import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ModalController } from '@ionic/angular';
import { UserService } from '../shared/services/user/user.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
})
export class SupportPage implements OnInit {
  @Input() title;
  @Input() path;
  email;
  phone;
  userId;

  constructor(public modalController: ModalController, private userService: UserService) { }

  ngOnInit() {

    this.userService.profile.asObservable().subscribe(user => {
      this.email = user.email;
      this.phone = user.phone_number ? user.phone_number : null;
      this.userId = user.id;
    })

  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
