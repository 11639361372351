import { Component, Input, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router, RoutesRecognized } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { UserService } from "../shared/services/user/user.service";
import { environment } from "../../environments/environment";
import { LoadingController } from '@ionic/angular';
import { filter, pairwise } from "rxjs/operators";
import { BasicService } from "../shared/services/basic/basic.service";


@Component({
  selector: "app-auth",
  templateUrl: "./auth.page.html",
  styleUrls: ["./auth.page.scss"],
})
export class AuthPage implements OnInit {
  @Input()
  popup = false;
  verifyEmailNotice = false;
  email = "";
  usrData;
  verificationMailSent = false;
  sendingMail = false;
  navigateTo = "/user";
  profile;
  processing = false;
  missingInfo=false;
  isModalOpen = true;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    public modalController: ModalController,
    private userService: UserService,
    private loadingCtrl: LoadingController,
    private basicService: BasicService
  ) {}

  ngOnInit() {

  const previousUrl = this.basicService.previousUrl.value
  //console.log("pr", previousUrl)

   if(previousUrl && !(previousUrl.startsWith('/auth/'))) {
    this.navigateTo = previousUrl;
   } 

  
    let redirectpath = this.router.url;
    if(redirectpath.startsWith('/auth/r/')) {
      this.navigateTo = redirectpath.replace('/auth/r', '');
    }
    this.afAuth.user.subscribe(async (usrData) => {
      if (usrData && usrData.uid) {
        this.usrData = usrData;
        // console.log("Email statyus", usrData.emailVerified)
        this.userService.getProfile(usrData);
        if (usrData.emailVerified) {
          this.checkProfile();
        } else {
          this.verifyEmailNotice = true;
          this.email = usrData.email;
        }
      }
    });
  }


  ngOnDestroy(){
    // console.log("Out of Page");
    this.isModalOpen = false;
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }
  
  checkProfile() {
    this.processing=true;
    this.userService.profile.asObservable().subscribe((profile) => {
      this.profile = profile;
      if (this.profile) {
        this.processing = false;
        if (!this.profile.profile_completed) {
          // console.log(this.profile);
        } else {
          this.proceed();
        }
      }
    });
  }

  proceed() {
    if (this.popup) {
      this.dismiss(true);
    } else {
      this.router.navigateByUrl(this.navigateTo);
    }
  }

  logout() {
    this.afAuth.signOut().then(() => {
      this.verifyEmailNotice = false;
      this.router.navigateByUrl('/auth');
    });
  }

  sendVerificationEmail() {
    this.sendingMail = true;
    this.afAuth.currentUser.then((usr) => {
      usr.sendEmailVerification({ url: environment.app.url + this.navigateTo })
        .then(() => {
          this.sendingMail = false;
          this.verificationMailSent = true;
        });
    });
  }

  async profileSaved() {
    const loading = await this.loadingCtrl.create({
      message: 'Please Wait...',
      duration: 5000,
    });

    loading.present();
  }

  dismiss(authComplete = false) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      "dismissed": true,
      "authComplete": authComplete,
    });
  }
}
