import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, PopoverController } from '@ionic/angular';
import { SupportPage } from 'src/app/support/support.page';
// import { NgNavigatorShareService } from 'ng-navigator-share';
import { SharePopOverComponent } from '../../core/share-pop-over/share-pop-over.component';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(public modalController: ModalController, public actionSheetController: ActionSheetController, public alertController: AlertController, public popoverController: PopoverController, private router: Router) { }


  async support(title: string = null, reqPath: string) {
    const modal = await this.modalController.create({
      component: SupportPage,
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      componentProps: {
        'title': title ? title : null,
        'path': reqPath,
      }
    });
    return await modal.present();
  }

  async simpleAlert(header, subHeader, message, button = 'OK') {
    const alert = await this.alertController.create({
      cssClass: 'simple-alert',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [button]
    });

    await alert.present();
  }

  async pageNotFound(){
    this.router.navigate(['/', '404'], {skipLocationChange: true})
  }


  async share(url: string, body: string) {

    if (!url) url = window.location.href;

    this.sharePopUp(url, body);
      return;

      // removing app share due to icompaible npm package
      /* 
    if (!this.ngNavShare.canShare()) {
      // alert(`This service/api is not supported in your Browser`);
      this.sharePopUp(url, body);
      return;
    }

    this.ngNavShare.share({
      title: body,
      text: body,
      url: url
    }).then((response) => {
      console.log(response);
    })
      .catch((error) => {
        console.log(error);
      }); */

  }

  /* this.share = [
    {
      name: 'WhatsApp',
      url: 'https://api.whatsapp.com/send?text=' + this.body + ' ' + this.url,
      icon: 'logo-whatsapp'
    },
    {
      name: 'FaceBook',
      url: 'https://www.facebook.com/sharer.php?u=' + this.url,
      icon: 'logo-facebook'
    }
  ] */

  async sharePopUp(url, body) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Share',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'LinkedIn',
        icon: 'logo-linkedin',
        handler: () => {
          this.shareOpenLink('li', url, body);
        }
      },
      {
        text: 'Whatsapp',
        icon: 'logo-whatsapp',
        handler: () => {
          this.shareOpenLink('wa', url, body);
        }
      },
       {
        text: 'Facebook',
        icon: 'logo-facebook',
        handler: () => {
          this.shareOpenLink('fb', url, body);
        }
      },
      {
       text: 'Twitter',
       icon: 'logo-twitter',
       handler: () => {
         this.shareOpenLink('tw', url, body);
       }
     },
     {
      text: 'Email',
      icon: 'mail',
      handler: () => {
        this.shareOpenLink('em', url, body);
      }
    }
    ]});

    await actionSheet.present();
  }

  async shareModal(url, body) {
    const modal = await this.modalController.create({
      component: SharePopOverComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        url: url,
        body: body
      }
    });
    return await modal.present();
  }

  shareOpenLink(type, url, body) {
    const share = {
      wa: 'https://api.whatsapp.com/send?text=' + body + ' ' + url,
      fb: 'https://www.facebook.com/sharer.php?u=' + url,
      tw: 'https://twitter.com/share?url=' + url + '&text=' + body,
      li: 'https://www.linkedin.com/shareArticle?url=' + url + '&title=' + body,
      em: 'mailto:?subject=' + body  + '&body=Check out this site: ' + url,
    } 

    window.open(share[type], "_blank");
  }

}
