import { Component, Input, OnInit } from '@angular/core';
import { GalleryService } from '../../services/gallery/gallery.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
@Input() id:string;
gallery;
settings = {
  counter: false,
  download: false,
};

  constructor(private galleryService: GalleryService) { }

  ngOnInit() {
    this.galleryService.getGallery(this.id).then(res => {
      this.gallery = res;
    })
  }

}
