import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-share-pop-over',
  templateUrl: './share-pop-over.component.html',
  styleUrls: ['./share-pop-over.component.scss'],
})
export class SharePopOverComponent implements OnInit {
@Input() url;
@Input() body;

share;
  
  constructor() { }

  ngOnInit() {
    this.share = [
      {
        name: 'WhatsApp',
        url: 'https://api.whatsapp.com/send?text=' + this.body + ' ' + this.url,
        icon: 'logo-whatsapp'
      },
      {
        name: 'FaceBook',
        url: 'https://www.facebook.com/sharer.php?u=' + this.url,
        icon: 'logo-facebook'
      }
    ]
  }

  
}
