import { NgModule } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from "@angular/router";
import {
  AngularFireAuthGuard,
  canActivate,
  emailVerified,
  redirectUnauthorizedTo,
} from "@angular/fire/compat/auth-guard";
import { HeadbarComponent } from "./user/shared/headbar/headbar.component";
import { PageNotFoundPage } from "./page-not-found/page-not-found.page";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["auth"]);

const routes: Routes = [
  /* {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  }, */

  {
    path: "home",
    component: HeadbarComponent,
    resolve: {
      url: "externalUrlRedirectResolver",
    },
    data: {
      externalUrl: "https://agilevirgin.in",
    },
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },

  {
    path: "experience/:brand",
    loadChildren: () =>
      import("./events/brand/brand.module").then((m) => m.BrandPageModule),
  },
  {
    path: "event/:brand/:event",
    loadChildren: () =>
      import("./events/event/event.module").then((m) => m.EventPageModule),
  },
  {
    path: "experience/:brand/:event",
    loadChildren: () =>
      import("./events/event/event.module").then((m) => m.EventPageModule),
  },
  {
    path: "user",
    loadChildren: () =>
      import("./user/user.module").then((m) => m.UserPageModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((m) => m.AuthPageModule),
  },
  {
    path: "upload",
    loadChildren: () =>
      import("./shared/components/upload/upload.module").then((m) =>
        m.UploadPageModule
      ),
  },
  {
    path: "page/:slug",
    loadChildren: () =>
      import("./page/page.module").then((m) => m.PagePageModule),
  },
  {
    path: "proposals/:id",
    loadChildren: () =>
      import("./events/proposals/proposals.module").then((m) =>
        m.ProposalsPageModule
      ),
  },
  {
    path: "proposal/:id",
    loadChildren: () =>
      import("./events/proposal/proposal.module").then((m) =>
        m.ProposalPageModule
      ),
  },
  {
    path: "support",
    loadChildren: () =>
      import("./support/support.module").then((m) => m.SupportPageModule),
  },
  {
    path: "404",
    loadChildren: () =>
      import("./page-not-found/page-not-found.module").then((m) =>
        m.PageNotFoundPageModule
      ),
  },
  {
    path: "profile/:id",
    loadChildren: () =>
      import("./view-profile/view-profile.module").then((m) =>
        m.ViewProfilePageModule
      ),
  },
  {
    path: 'goto/:id',
    loadChildren: () => import('./goto/goto.module').then( m => m.GotoPageModule)
  },
  {
    path: "**",
    loadChildren: () =>
      import("./page-not-found/page-not-found.module").then((m) =>
        m.PageNotFoundPageModule
      ),
  },
  
  // { path: "**", redirectTo: "/404", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: "externalUrlRedirectResolver",
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
  ],
})
export class AppRoutingModule {}
