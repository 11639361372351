import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './nav/nav.component';
import { IonicModule } from '@ionic/angular';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { EventMenuComponent } from './event-menu/event-menu.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SharePopOverComponent } from './share-pop-over/share-pop-over.component';
import { GalleryComponent } from './gallery/gallery.component';
// import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import { SafehtmlPipe } from './pipes/safehtml/safehtml.pipe';
import { ProposalCardComponent } from './proposal-card/proposal-card.component';
import { PeopleCardComponent } from './people-card/people-card.component';
import { TickerComponent } from './ticker/ticker.component';
import { FormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular/13';






@NgModule({
  declarations: [NavComponent, FooterComponent, EventMenuComponent, SectionTitleComponent, VerifyEmailComponent, SharePopOverComponent, GalleryComponent, SafehtmlPipe, ProposalCardComponent, PeopleCardComponent, TickerComponent],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule,
    MatMenuModule,
    MatButtonModule,
    // CrystalLightboxModule,
    FormsModule,
    LightgalleryModule
  ],
  exports: [NavComponent, FooterComponent, EventMenuComponent, SectionTitleComponent, VerifyEmailComponent, SharePopOverComponent, GalleryComponent, SafehtmlPipe, ProposalCardComponent, PeopleCardComponent, TickerComponent]
})
export class CoreModule { }
