import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private db: AngularFireDatabase) { }


  getGallery(id: string) {
    return this.db.database.ref('gallery/' + id).get().then(res => {
      const data = res.exportVal();
      return {
        ...data,
        images: data['images'] ? _.values(data['images']) : null
      }
    })
  }
}
