import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasicService } from '../../services/basic/basic.service';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
@Output() scroll: EventEmitter<any> = new EventEmitter();


brands;

profile;

constructor(private basicService: BasicService, private userService: UserService, private authService: AuthService) { }
  
  ngOnInit() {
   this.basicService.getBrands().then(res => {
    this.brands = res;
    // console.log('ttt', this.brands);
   });
   this.profile = this.userService.profile.asObservable();
  }

  logout(){
    this.authService.logout();
  }

  scrollTo(tag: string){
    this.scroll.emit(tag);
  }
}
